<template>
  <div class="border2">
    <div class="bgC8 pdtb10 pdl32">
      <el-checkbox
        v-model="checkAll"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
      >
        <span class="fontC2 font16">{{ title }}</span>
      </el-checkbox>
    </div>
    <div class="pdl32">
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <div class="flex_start flex_wrap">
          <div v-for="(item, index) in dataList" :key="index" class="pdtb12 bordrb2 w16p">
            <el-checkbox :label="item">{{ item.name }}</el-checkbox>
          </div>
        </div>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    type: {
      default: "web",
    },
    list: {
      default: [],
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isIndeterminate: false,
      checkAll: false,
      checkedCities: [],
      dataList: [],
    };
  },
  watch: {
    list(newval) {
      if (newval && newval.length > 0) {
        if (this.dataList.length) {
          this.setList();
        }
      }
    },
    checkedCities(newval) {
      this.$emit("input", newval);
    },
  },
  created() {},
  mounted() {
    if (this.type == "web") {
      this.getWebModuleRole();
    } else {
      this.getWxAppModuleRole();
    }
  },
  methods: {
    handleCheckAllChange(value) {
      if (value) {
        this.checkedCities = this.dataList;
        this.isIndeterminate = false;
      } else {
        this.checkedCities = [];
      }
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.dataList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.dataList.length;
    },
    //获取web权限
    getWebModuleRole() {
      if (!this.companyId) return;
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getWebModuleRole,
          params: {
            companyId: this.companyId,
          },
        })
        .then(({ data }) => {
          this.dataList = data;
          if (this.list && this.list.length > 0) {
            this.setList();
          }
        });
    },
    getWxAppModuleRole() {
      if (!this.companyId) return;
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getWxAppModuleRole,
          params: {
            companyId: this.companyId,
          },
        })
        .then(({ data }) => {
          this.dataList = data;
          if (this.list && this.list.length > 0) {
            this.setList();
          }
        });
    },
    setList() {
      if (this.checkedCities.length == 0) {
        const n_arr = [];
        for (let i = 0; i < this.list.length; i++) {
          const element = this.list[i];
          const datas = this.dataList.find((val) => val.id == element);
          if (datas) {
            n_arr.push(datas);
          }
        }
        this.checkedCities = n_arr;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
